import { ArrowDropUp, Dangerous, MoreVert, ReportProblem, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import toastNow from "../helpers/toast";



export default function ReportMember(props: { memberId: string, close: Function }) {
    const { t } = useTranslation();

    const actions = [
        {
            key: 'report',
            val: t('report_problem'),
        }
    ];

    const report = async (cause: string) => {
        props.close();

        if (!window.confirm(t('confirm_action'))) {
            return;
        }

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URI;
        const req = await fetch(`${apiBaseUrl}/contact/${props.memberId}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                report: true,
            })
        });

        if(req.ok) {
            toastNow({ message: t('report_success_message'), title: t('report_problem'), type: 'report' });
        }
        // const data = await req.json();
    }

    return (
        <ul className={[
            "bg-primary-50 text-primary-500 dark:bg-primary-950/90 dark:text-primary-300/80 rounded-b-lg absolute ltr:left-1 rtl:right-1 top-10 z-40",
            "shadow-primary-300 dark:shadow-primary-700/50"
        ].join(' ')}
            style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}>
            <ArrowDropUp className="absolute -top-[14px]  text-primary-100 dark:text-primary-900" />
            {
                actions.map(action =>
                    <li key={action.key} onClick={() => report(action.key)}
                        className={["last:rounded-b-lg flex cursor-pointer hover:bg-primary-100 dark:hover:bg-primary-900 ltr:pr-6 rtl:pl-6 py-1 [nth-child(2)]:pt-2 last:pb-2"].join(' ')}
                    >
                        <ReportProblem className="w-4 h-4 text-amber-500 mx-1" /> {action.val}
                    </li>
                )
            }
        </ul>
    )
}