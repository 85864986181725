import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { userType, memberType, messageType } from "../helpers/types/types";
import { generateShortName, getBgHexaColorFromName } from "../helpers/common";
import { MoreVert, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ChatRoom from "./ChatRoom";
import toastNow from "../helpers/toast";
import { Toaster } from "react-hot-toast";
import TopMenu from "./TopMenu";


const Welcome = forwardRef((props: { toggleDarkMode: Function, logout: Function, user: userType, handleChangeLanguage: Function }, ref) => {
    const { user, logout } = props;
    const { t } = useTranslation();
    const [searchContactsValue, setSearchContactsValue] = useState('');
    const searchContactsRef = useRef<HTMLInputElement>(null);
    const [members, setMembers] = useState<memberType[]>([]);

    // Sockets 
    const [socket, setSocket] = useState<WebSocket>();
    const roomName = 'abdelilah-duo-room';
    // const socketUrl = process.env.REACT_APP_API_BASE_URI?.replaceAll(/http/g, 'ws');
    const socketUrl = process.env.REACT_APP_API_BASE_URI?.replaceAll(/http/g, 'ws');


    useEffect(() => {
        restoreContactsList(true);

        init();

        return () => {
            // Close socket connection
            socket?.close();
        };
    }, [])


    const init = () => {
        openWebSocketConnection();
    }

    const openWebSocketConnection = () => {
        if (!user) return;

        const fullSocketUrl = `${socketUrl}/ws/chat/${user.pseudoname}/${user.id}/${roomName}/`;
        const ws: WebSocket = new WebSocket(fullSocketUrl);
        setSocket(ws);

        ws.onopen = () => {
            // console.log("Connected to WebSocket");
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.type === "online_users") {

                // Retrieving old members from localStorage; working with state's members is quite faster and better
                let oldContacts: memberType[] = [];
                const __oldContacts = localStorage.getItem('contactLists');
                if (__oldContacts) {
                    oldContacts = JSON.parse(__oldContacts);
                }

                const onlineMembers: { user_uid: string, user_name: string }[] = data.users;
                const newMembersUids = onlineMembers.map(y => y.user_uid);

                const membersLeft = oldContacts.filter(x => !newMembersUids.includes(x.id));
                const membersExisting = oldContacts.filter(x => newMembersUids.includes(x.id));

                const membersNew: memberType[] = onlineMembers.filter(om => om.user_uid != user.id.toString() && -1 == oldContacts.findIndex(m => m.id.toString() == om.user_uid)).map(member => ({
                    id: member.user_uid,
                    pseudoname: member.user_name,
                    messages: [],
                    isOnline: true,
                    isOpen: false,
                }));

                const contactsList = [...membersExisting.map(x => ({ ...x, isOnline: true })), ...membersNew, ...membersLeft.map(x => ({ ...x, isOnline: false, isOpen: x.isOpen }))];

                setMembers(contactsList);
                saveContactListsIntoLocalStorage(contactsList);
            }

            if (data.type == 'message' && !isChatRoomOpen()) {
                const newMessage: messageType = {
                    id: Math.random().toString().replace('.', ''),
                    value: data.message,
                    receiver_id: data.recipient_uid,
                    sender_id: data.sender_uid,
                    sent_at: new Date(),
                    read_at: null,
                    updated_at: null,
                };
                appendMembersMessage(newMessage);
                // Temporary toast; while not displaying the unreadMessages count
                toastNow({ message: newMessage.value, title: data.sender_name, type: 'message' });
            }
        };

        ws.onclose = () => {
            console.log("WebSocket disconnected");
        };

        ws.onerror = (error) => {
            console.error("WebSo+cketMessageType error:", error);
        };

        return () => {
            ws.close();
        };
    }

    const appendMembersMessage = (newMessage: messageType) => {
        // Append message to the corresponding member
        // Still have to re-render
        setMembers((prev) => prev.map(member => (member.id != newMessage.sender_id ? member : { ...member, messages: [...member.messages, newMessage] })));
    }

    const openConversation = (member: memberType) => {
        closeChatRoom();

        // An amount of time waiting between close and open new conversation
        setTimeout(() => {
            // Mark conversation as read
            const _members = members;
            const __ = _members.map(m => m.id == member.id ? { ...m, isOpen: true, messages: m.messages.map(msg => ({ ...msg, read_at: new Date() })) } : { ...m, isOpen: false })
            setMembers(__);
            saveContactListsIntoLocalStorage(__);
        }, 100);
    }

    const isChatRoomOpen = (): boolean => {
        return members.filter(x => x.isOpen).length > 0;
    }

    useImperativeHandle(ref, () => {
        return {
            closeSocket: () => {
                socket?.close();
            }
        }
    })

    const closeChatRoom = () => {
        const openRoomMember = members.filter(x => x.isOpen);
        if (!openRoomMember.length) return;
        const member = openRoomMember[0];
        // Mark conversation which is being closing as read
        const __members = members.map(x => (member.id != x.id ? x : { ...x, isOpen: false, messages: x.messages.map(msg => ({ ...msg, read_at: new Date() })) }))
        setMembers(__members);
        // Save to localStorage
        saveContactListsIntoLocalStorage(__members);
    }



    //[ Save/Retrieve contacts list into/from localStorage
    const saveContactListsIntoLocalStorage = (data: memberType[]) => {
        const contacts = data.map(contact => ({ ...contact, messages: contact.messages.filter(msg => msg.sender_id != user.id && !msg.read_at) }));
        if (contacts.length) {
            localStorage.setItem('contactLists', JSON.stringify(contacts));
        }
    }

    const restoreContactsList = (resetIsOpenToFalse = false) => {
        const oldContacts = localStorage.getItem('contactLists');
        if (!oldContacts) return;

        const __contacts: memberType[] = JSON.parse(oldContacts);

        if (resetIsOpenToFalse) {
            setMembers(__contacts.map(contact => ({ ...contact, isOpen: false })));
        } else {
            setMembers(__contacts.map(contact => contact));
        }
    }
    //]


    const getFilteredMembers = (): memberType[] => {
        if (!searchContactsValue || !searchContactsValue.trim()) return members;

        return members.filter(m => m.pseudoname.toLowerCase().includes(searchContactsValue.trim().toLowerCase()));
    }


    return (
        <div className="fixed md:relative top-0 left-0 right-0 bottom-4 transition-all bg-primary-50/30 dark:bg-black">
            {/* top menu */}
            <TopMenu handleChangeLanguage={props.handleChangeLanguage} logout={logout} toggleDarkMode={props.toggleDarkMode} user={user} />

            {/* Page content */}
            <div className=" mt-2 md:flex bg-primary-50/30 dark:bg-black md:rounded-md p-2 z-10">

                {/* Contacts list */}
                <div className={[isChatRoomOpen() ? 'hidden' : '', " md:block md:w-2/5 h-[90vh] md:h-[70vh] p-2 rounded-md bg-white dark:bg-primary-950/20 shadow-primary-300 dark:shadow-primary-700/50"].join(' ')} style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}>
                    {/* Search contact */}
                    <div className="bg-primary-500 dark:bg-primary-900/20 p-1 flex rounded-md mb-2">
                        <input onChange={(e) => setSearchContactsValue(e.target.value)} defaultValue={searchContactsValue} ref={searchContactsRef} className="w-[calc(100%-30px)] px-1 py-2 bg-transparent text-white dark:text-primary-300 placeholder-white dark:placeholder-primary-500 outline-0 border-0" placeholder={t('search')} />
                        <button onClick={() => { searchContactsRef.current?.focus() }} className="ml-2 outline-0 border-0 rounded-full w-10 h-10 text-center bg-white dark:bg-primary-950">
                            <Search className="text-gray-700 dark:text-primary-300 w-4 h-4" />
                        </button>
                    </div>


                    {/* Contacts */}
                    <div className="overflow-y-scroll h-[calc(100%-60px)] scrollbar-thin scrollbar-thumb-primary-50/80 scrollbar-track-white dark:scrollbar-thumb-primary-950/50 dark:scrollbar-track-black/90">
                        {
                            getFilteredMembers().map(member => (
                                <div key={member.id} className={['hover:bg-primary-50 dark:hover:bg-black/20 rounded-xl my-1 cursor-pointer flex transition', member.isOpen ? 'bg-primary-50 dark:bg-black/20' : ''].join(' ')}>
                                    <div onClick={() => openConversation(member)} className='relative my-2 p-2 text-center align-middle border border-primary-300 dark:border-primary-500 rounded-full w-10 h-10 text-primary-50 dark:text-primary-100' style={{ backgroundColor: getBgHexaColorFromName(member.pseudoname).toString() }}>
                                        {
                                            member.isOnline
                                                ? <p className='top-0 ltr:right-0 rtl:left-0 absolute w-3 h-3 rounded-full bg-emerald-500 border border-primary-300'></p>
                                                : <p className='top-0 ltr:right-0 rtl:left-0 absolute w-3 h-3 rounded-full bg-amber-500 border border-primary-300'></p>
                                        }
                                        {generateShortName(member.pseudoname)}
                                    </div>
                                    <div onClick={() => openConversation(member)} className='my-auto flex mx-2 w-full'>
                                        <span className='text-primary-700 dark:text-primary-300 mx-2'>{member.pseudoname.split('')[0].toUpperCase() + member.pseudoname.split('').slice(1).join('')}</span>

                                        {
                                            !member.isOpen && member.messages.filter(m => m.sender_id != user.id && !m.read_at).length
                                                ? <span className='ml-1 italic text-primary-700/70 dark:text-primary-300/70'>({member.messages.filter(m => (m.sender_id != user.id && !m.read_at)).length})</span>
                                                : ''
                                        }
                                    </div>
                                </div>
                            ))
                        }
                        {
                            getFilteredMembers().length ? <></>
                                : (<div className="text-center text-primary-500 dark:text-primary-900 w-full pt-4">
                                    <Search className="" style={{ height: '80px', width: '80px' }} />
                                </div>)
                        }
                    </div>
                </div>


                {/* Conversation */}
                <div className={
                    [
                        isChatRoomOpen() ? '' : 'hidden md:block',
                        "md:w-full md:ml-4 p-2 md:mt-[7px] rounded-md bg-white shadow-primary-300 dark:shadow-primary-700/50 dark:bg-primary-950/20",
                        'absolute top-[70px] md:top-auto bottom-2 left-1 right-1 md:relative md:h-[70vh]'
                    ].join(' ')}
                    style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}>
                    {
                        !isChatRoomOpen()
                            ? <div className={[getFilteredMembers().length ? '' : 'hidden', "text-center text-primary-500 dark:text-primary-900 pt-20"].join(' ')}>
                                {/* <Drafts className="" style={{ height: '120px', width: '120px' }} /> */}
                                <img src="/logo.png" className="mx-auto" alt="dardacha.live" style={{ height: '100px', width: 'auto' }} />
                            </div>
                            : <ChatRoom appendMessageToMember={appendMembersMessage} user={user} member={members.filter(x => x.isOpen)[0]} socket={socket} close={() => closeChatRoom()} />
                    }
                </div>
            </div>

            {/* Notifications */}
            <Toaster />
        </div>
    );
})

export default Welcome;


