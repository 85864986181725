import { userType } from "../helpers/types/types"
import { generateShortName } from "../helpers/common";
import { ArrowDropUp, Check, Language, LightMode, Logout, MoreVert } from "@mui/icons-material";
import { useState } from "react";
import ReportMember from "./ReportMember";
import PrivacyPolicy from './PrivacyPolicy';
import { useTranslation } from "react-i18next";


const TopMenu = (props: { toggleDarkMode: Function, logout: Function, user: userType, handleChangeLanguage: Function }) => {
    const { user } = props;
    const curLang = localStorage.getItem('_lang');
    const { t } = useTranslation();
    const languages: {
        key: string;
        val: string;
    }[] = [
            { key: 'ar', val: 'العربية' },
            { key: 'en', val: 'English' },
            { key: 'es', val: 'Española' },
            { key: 'fr', val: 'Français' },
        ];

    const [showLangMenu, setShowLangMenu] = useState(false);

    const changeLang = (lang: string) => {
        props.handleChangeLanguage(lang);
        setShowLangMenu(false);
    }


    const [moreActions, setMoreActions] = useState(false);

    // Privacy And Policy PP
    const [ppOpen, setPPOpen] = useState(false);

    return (
        <div className="w-full bg-primary-50 md:bg-primary-50/30 dark:bg-black z-30 flex sticky top-0 right-0.5 md:relative">
            {/* Logo */}
            <div className="min-w-fit md:w-full pt-1">
                <a href="/">
                    <img src="/logo.png" className="h-12 w-auto ml-1 align-middle" alt="dardacha.live" />
                </a>
            </div>

            {/* User */}
            <div className='rounded-xl flex p-1 pt-2 w-full md:w-auto'>
                <div className='hidden md:block relative p-2 text-center align-middle rounded-full w-10 h-10 text-primary-100 dark:text-primary-300 bg-primary-500 dark:bg-primary-950/80'>
                    <span className="text-sm md:text-base my-auto">{generateShortName(user.pseudoname)}</span>
                </div>
                <div className='w-full md:w-max my-auto ml-2 flex text-primary-500 dark:text-primary-300'>
                    <span className='mx-auto'>{user.pseudoname.split('')[0].toUpperCase() + user.pseudoname.split('').slice(1).join('')}</span>
                </div>
            </div>

            <div className="flex p-1 pt-2 rounded-md md:bg-white dark:bg-primary-950/0">

                {/* Switch theme mode */}
                <div className="ml-4">
                    <button onClick={() => props.toggleDarkMode()} className="border-none outline-none my-auto p-1 md:p-2 rounded-full bg-primary-100 hover:bg-primary-200/80 dark:bg-primary-950/80 dark:hover:bg-primary-900 transition">
                        <LightMode className="text-primary-500 dark:text-primary-300" />
                    </button>
                </div>

                {/* Lang button */}
                <div className="ml-1 relative">
                    <button onClick={() => { setMoreActions(false); setShowLangMenu((prev) => !prev) }} className="border-none outline-none my-auto p-1 md:p-2 rounded-full bg-primary-100 hover:bg-primary-200/80 dark:bg-primary-950/80 dark:hover:bg-primary-900 transition">
                        <Language className="text-primary-500 dark:text-primary-300" />
                    </button>

                    <ul className={[
                        !showLangMenu ? 'hidden' : '',
                        "bg-primary-50 text-primary-500 dark:bg-primary-950/90 dark:text-primary-300/80 rounded-b-lg absolute ltr:right-1 rtl:left-1 top-10 md:top-12",
                        "shadow-primary-300 dark:shadow-primary-700/50 p-1"
                    ].join(' ')}
                        style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}>
                        <ArrowDropUp className="absolute -top-[14px] ltr:right-1 rtl:left-1 text-primary-100 dark:text-primary-950/80" />
                        {
                            languages.map(lang =>
                                <li key={lang.key} onClick={() => changeLang(lang.key)}
                                    className={[curLang == lang.key ? 'bg-primary-100/80 flex dark:bg-primary-900/80 pl-1 pr-1' : 'ltr:pl-8 rtl:pr-8', "ltr:pr-8 rtl:pl-8 last:rounded-b-lg cursor-pointer hover:bg-primary-100 dark:hover:bg-primary-900 py-1 [nth-child(2)]:pt-2 last:pb-2"].join(' ')}
                                > {curLang == lang.key ? <Check className="text-base mr-1" /> : <></>}
                                    {lang.val}
                                </li>
                            )
                        }
                    </ul>
                </div>

                <div className="mx-2 relative">
                    <button onClick={() => { setShowLangMenu(false); setMoreActions((prev) => !prev) }} className="border-none outline-none my-auto p-1 md:p-2 rounded-full bg-primary-100 hover:bg-primary-200/80 dark:bg-primary-950/80 dark:hover:bg-primary-900 transition">
                        <MoreVert className="text-primary-500 dark:text-primary-300" />
                    </button>

                    {
                        !moreActions ? <></>
                            : <ul className={[
                                "bg-primary-50 text-primary-500 dark:bg-primary-950/90 dark:text-primary-300/80 rounded-b-lg absolute ltr:right-0 rtl:left-0 top-10 md:top-12",
                                "shadow-primary-300 dark:shadow-primary-700/50 p-1"
                            ].join(' ')}
                                style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}>
                                <ArrowDropUp className="absolute -top-[14px] ltr:right-1 rtl:left-1 text-primary-100 dark:text-primary-950/80" />

                                <li onClick={() => { setPPOpen(true); setMoreActions(false) }} className={["w-max rounded-lg p-1 cursor-pointer hover:bg-primary-100 dark:hover:bg-primary-900"].join(' ')}>
                                    {t('privacy_and_policy')}
                                </li>
                                <li onClick={() => { setPPOpen(true); setMoreActions(false) }} className={["rounded-lg p-1 cursor-pointer w-full dark:hover:bg-primary-900"].join(' ')}>
                                    <button className="border-none outline-none" onClick={() => { props.logout() }}>
                                        <Logout className="rtl:rotate-180 text-primary-500 dark:text-primary-300 text-base mr-1" />
                                        <span className="">{t('logout')}</span>
                                    </button>
                                </li>
                            </ul>
                    }

                </div>
            </div>


            {/* Privacy and policy */}
            {
                !ppOpen
                    ? <></>
                    : <div className={[
                        "fixed top-0 left-0 right-0 bottom-0 backdrop-blur-sm z-40",
                        "w-full md:w-2/3 h-full md:py-24 mx-auto content-center"
                    ].join(' ')}>
                        <div className="place-content-center relative w-full h-full mx-auto content-center py-2 md:py-1">
                            <PrivacyPolicy close={() => setPPOpen(false)} />
                        </div>
                    </div>
            }

        </div>
    )
}


export default TopMenu;