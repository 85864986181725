import { CloseRounded, Message, ReportProblem } from '@mui/icons-material';
import toast, { Toaster } from 'react-hot-toast';

const toastNow = (payload: { message: string, title: string, type: string }) => {
    toast.custom((t) => (
        <div
            className={`${t.visible ? 'animate-enter' : 'animate-leave'
                } relative p-0 m-0 shadow-primary-300 dark:shadow-primary-700/50 max-w-sm w-full bg-white text-primary-900 dark:bg-black/80 dark:text-primary-200 shadow-lg rounded-lg flex`}
            style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }}
        >
            <div className="flex-1 w-0 p-2">
                <div className="flex items-start">
                    <div className="flex-shrink-0 pt-auto">
                        {
                            payload.type == 'report'
                                ? <ReportProblem />
                                : <Message />
                        }
                    </div>
                    <div className="ml-3 flex-1">
                        <p className='text-primary-900 dark:text-primary-200'>{payload.title}</p>
                        <p className="mt-1 text-sm text-primary-600 dark:text-primary-400">
                            {payload.message}
                        </p>
                    </div>
                </div>
            </div>
            <button
                onClick={() => toast.dismiss(t.id)}
                className="items-center justify-center focus:outline-none absolute top-1 right-1"
            >
                <CloseRounded className="text-primary-500 dark:text-primary-300 p-1 rounded-full bg-white dark:bg-black/80 w-8 h-8 shadow-primary-300 dark:shadow-primary-700/50" style={{ boxShadow: `0px 0px 5px 1px var(--tw-shadow-color)` }} />
            </button>
        </div>
    ))
}

export default toastNow;