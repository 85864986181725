import { ArrowDownward, ArrowUpward, Close } from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = (props: { close: Function }) => {
    const { t } = useTranslation();

    return (
        <div className="flex justify-center items-center min-h-screen p-4">
            <div className="w-full max-w-lg shadow-xl rounded-2xl bg-primary-50 dark:bg-black p-6 relative">
                <h2 className="w-full text-2xl font-bold text-center text-primary-950 dark:text-primary-300 mb-4">{t('privacy_and_policy_terms')}</h2>
                <button onClick={() => props.close()} className="h-8 w-8 text-center absolute top-0 right-0 bg-primary-50 rounded-full dark:bg-primary-950 dark:text-primary-500">
                    <Close className="w-4 h-4 hy-auto" />
                </button>
                <p className="text-gray-600 text-sm text-center mb-4">{t('last_updated_at')}</p>

                <div className="space-y-4">
                    <Section title={t('user_conduct_title')}>
                        {t('user_conduct_text')}
                    </Section>
                    <Section title={t('privacy_title')}>
                        {t('privacy_text')}
                    </Section>
                    <Section title={t('age_restriction_title')}>
                        {t('age_restriction_text')}
                    </Section>
                    <Section title={t('security_title')}>
                        {t('security_text')}
                    </Section>
                    <Section title={t('logout_title')}>
                        {t('logout_text')}
                    </Section>
                    <Section title={t('changes_title')}>
                        {t('changes_text')}
                    </Section>
                </div>

                <div className="mt-6 text-center">
                    <a href="mailto:contact@dardacha.live">
                        <button className="bg-primary-50 dark:bg-primary-950/80 text-primary-900 dark:text-primary-50 px-4 py-2 rounded-lg">
                            {t('contact_us')}
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};

const Section = (props: { title: string, children: any }) => {
    const { title, children } = props;
    const [open, setOpen] = useState(false);

    return (
        <div className="border-b dark:border-primary-950/50 border-primary-300 pb-2">
            <button
                className="flex justify-between items-center w-full text-left text-primary-900 dark:text-primary-300 font-medium"
                onClick={() => setOpen(!open)}
            >
                {title} {open ? <ArrowUpward className="w-4 h-4" /> : <ArrowDownward className="w-4 h-4" />}
            </button>
            {open && <p className="text-sm text-gray-600 dark:text-gray-500 mt-2">{children}</p>}
        </div>
    );
};

export default PrivacyPolicy;
